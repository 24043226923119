import React, { useCallback } from 'react';
import Script from 'react-load-script';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import Seo from '../components/seo';

import { SEND_PULSE_URL } from '../constants/SendPulse';

const Notifications = ({
  data: {
    markdownRemark: { 
      html
    }
  }
}) => {

  const onLoad = useCallback(() => {
    /* eslint no-undef: "off" */
    !!oSpP && oSpP.getVapidToken().then(isSubscribed => {
      document.cookie = `notifications=${isSubscribed};`;
      const path = isSubscribed
        ? '/notifications-error'
        : '/notifications-success';
      return navigate(path, { replace: true });
    })
      .catch((e) => {
        console.error(`Push notifications failed: ${e.message}`);
      });
  }, []);

  return (
    <>
      <Seo noindex />
      <div dangerouslySetInnerHTML={{ __html: html }} />
      <Script
        onLoad={onLoad}
        url={SEND_PULSE_URL}
      />
    </>
  );
};

Notifications.noLayout = true;

Notifications.propTypes = {
  data: PropTypes.object
};

export default Notifications;

export const query = graphql`
  query($lang: String!) {
    markdownRemark(
      frontmatter: { noLayout: { eq: true }, lang: { eq: $lang } }
    ) {
      html
    }
  }
`;
